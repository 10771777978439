//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from '../../../components/Popup.vue';

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";


export default {
  components: {
    Layout,
    PageHeader,
    Popup
},
  data() {
    return {
      submitted: false,

      cities: [],
      selectedCity: 0,

      metroLines: [],

      // Новая линия
      addLinePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newLine: {
        line_code: '',
        line_name: '',
        line_color: ''
      },

      // Новая станция
      addStationPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newStation: {
        line_id: '',
        station_name: '',
      },

      deleteLinePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedLineId: 0,

      deleteStationPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedStationId: 0,

      title: this.$t('menuitems.management.list.metro_stations'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.metro_stations'),
          active: true
        }
      ],
    };
  },
  validations: {
    newLine: {
      line_code: { required, minLength: minLength(1), maxLength: maxLength(10) },
      line_name: { required, minLength: minLength(1), maxLength: maxLength(255) },
      line_color: { required, minLength: minLength(1), maxLength: maxLength(7) },
    },
    newStation: {
      station_name: { required, minLength: minLength(1), maxLength: maxLength(255) }
    }
  },
  computed: {

  },
  watch: {
    selectedCity: function(val){
      this.getMetroLines(val);
    }
  },
  methods: {
    showAddStationPopup(line_id){
      this.newStation.line_id = line_id;
      this.addStationPopup.show = true;
    },
    closeAddStationPopup(){
      this.addStationPopup.show = false;
    },
    addStationPopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newStation.$touch();
      if(!this.$v.newStation.$invalid){
        let formData = new FormData();
        formData.append('line_id', this.newStation.line_id);
        formData.append('station_name', this.newStation.station_name);

        axios
            .post(`/v1/localization/add-metro-station`, formData)
            .then(() => {
              this.closeAddStationPopup();
              this.getMetroLines(this.selectedCity);
              this.submitted = false;
              this.newStation.line_id = '';
              this.newStation.station_name = '';

              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },

    showDeleteStationPopup(station_id){
      this.deletedStationId = station_id;
      this.deleteStationPopup.show = true;
    },
    closeDeleteStationPopup(){
      this.deleteStationPopup.show = false;
    },
    deleteStationPopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedStationId);

      axios
          .post(`/v1/localization/delete-metro-station`, formData)
          .then(() => {
            this.closeDeleteStationPopup();
            this.getMetroLines(this.selectedCity);

            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showDeleteLinePopup(lineId){
      this.deletedLineId = lineId;
      this.deleteLinePopup.show = true;
    },
    closeDeleteLinePopup(){
      this.deleteLinePopup.show = false;
    },
    deleteLinePopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedLineId);

      axios
          .post(`/v1/localization/delete-metro-line`, formData)
          .then(() => {
            this.closeDeleteLinePopup();
            this.getMetroLines(this.selectedCity);

            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showAddLinePopup(){
      if(this.selectedCity === 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.metro_stations.no_select_city'),
          time: 3,
          color: "danger"
        });
        return false;
      }
      this.addLinePopup.show = true;
    },
    closeAddLinePopup(){
      this.addLinePopup.show = false;
    },
    addLinePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newLine.$touch();
      if(!this.$v.newLine.$invalid){
        let formData = new FormData();
        formData.append('city_id', this.selectedCity);
        formData.append('line_code', this.newLine.line_code);
        formData.append('line_name', this.newLine.line_name);
        formData.append('line_color', this.newLine.line_color);

        axios
            .post(`/v1/localization/add-metro-line`, formData)
            .then(() => {
              this.closeAddLinePopup();
              this.getMetroLines(this.selectedCity);
              this.submitted = false;
              this.newLine.line_code = '';
              this.newLine.line_name = '';
              this.newLine.line_color = '';

              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    getMetroLines(city_id){
      let formData = new FormData();
      formData.append('city_id', city_id);

      axios
          .post(`/v1/localization/get-metro-lines`, formData)
          .then(resp => {
            this.metroLines = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCities(){
      axios
          .get(`/v1/localization/get-city`)
          .then(resp => {
            this.cities = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getCities();
  }
};
